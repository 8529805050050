import { graphql } from "gatsby";

export const CompetenciesFragment = graphql`
  fragment Competencies on PRISMIC_Who_we_are_pageBodySection {
    fields {
      competency_title
      competency_description
    }
  }
`;
