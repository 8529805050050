import { Section } from "@/components";
import { DataContext } from "@/pages/who-we-are";
import { theme } from "@/styles";
import styled from "@emotion/styled";
import { get } from "lodash";
import { RichText } from "prismic-reactjs";
import React, { useContext } from "react";

const Item = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.blue};
  }
`;

const Competencies = () => {
  const data = useContext(DataContext);
  const fields = get(
    data,
    "prismic.allWho_we_are_pages.edges[0].node.body[0].fields",
    []
  );

  return (
    <Section id="about-us">
      <div className="uk-container uk-container-small">
        <h2 className="uk-text-center uk-margin-large-bottom">
          What makes us different
        </h2>
        <div className="uk-grid uk-child-width-1-2@s" data-uk-grid>
          {fields.map(({ competency_title, competency_description }, index) => (
            <Item key={index.toString()}>
              <RichText render={competency_title} />
              <RichText render={competency_description} />
            </Item>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Competencies;
