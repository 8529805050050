import { ScrollTo, Section } from "@/components";
import { DataContext } from "@/pages/who-we-are";
import { get } from "lodash";
import { RichText } from "prismic-reactjs";
import React, { useContext } from "react";

const AboutUs = () => {
  const data = useContext(DataContext);
  const node = get(data, "prismic.allWho_we_are_pages.edges[0].node");

  if (!node) return null;

  return (
    <Section>
      <div className="uk-container uk-container-small uk-margin-large-bottom">
        <div className="uk-width-2-3@s">
          <RichText render={node.title || []} />
          <RichText render={node.subtitle || []} />
          <RichText render={node.quote || []} />
        </div>
      </div>
      <br />
      <ScrollTo href="#about-us" />
      <br />
    </Section>
  );
};

export default AboutUs;
