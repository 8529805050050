import { Blob, BoostYourBusiness } from "@/components/sections";
import {
  AboutUs,
  Competencies,
  Illustration
} from "@/components/sections/WhoWeArePage";
import { CompetenciesFragment } from "@/fragments";
import { StaticQuery, graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic-graphql";
import React from "react";

export const DataContext = React.createContext({});

export const query = graphql`
  query {
    prismic {
      allWho_we_are_pages {
        edges {
          node {
            title
            subtitle
            quote
            body {
              ...Competencies
            }
          }
        }
      }
    }
  }
`;

const WhoWeArePage = ({ data }) => (
  <DataContext.Provider value={data}>
    <AboutUs />
    <Illustration />
    <Competencies />
    <BoostYourBusiness />
    <Blob />
  </DataContext.Provider>
);

WhoWeArePage.query = query;
WhoWeArePage.fragments = [CompetenciesFragment];

export default () => (
  <StaticQuery
    query={`${query}`}
    render={withPreview(
      data => (
        <WhoWeArePage data={data} />
      ),
      query
    )}
  />
);
