import { graphql } from "gatsby";

export const OurServicesFragment = graphql`
  fragment OurServices on PRISMIC_HomepageBodyOur_services {
    type
    label
    fields {
      illustration
      name
      caption
    }
    primary {
      text
    }
  }
`;
