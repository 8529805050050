import React from "react";

import drawing from "./drawing-5.svg";

const Illustration = () => (
  <section className="section uk-text-center">
    <div
      style={{
        width: "100vw",
        height: "200px",
        marginTop: "0px",
        zIndex: -1,
        position: "relative",
        backgroundImage: "url(" + drawing + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "bottom center",
        maxWidth: "100%",
      }}
      data-uk-parallax="bgy: 20, -20"
    ></div>
  </section>
);

export default Illustration;
